/*
 * @Date: 2023-02-16 14:48:25
 * @LastEditors: 'lidianbin' 'lidianbin@coocaa.com'
 * @LastEditTime: 2024-12-03 11:47:14
 * @FilePath: \cms_main_site\src\services\fetch.js
 */
import qs from 'qs'
import axios from 'axios'
import store from '@/store'
// import NProgress from 'nprogress'
// import { Loading, Notification } from 'element-ui'
import { CoocaaLoading as Loading, Notification } from '@cseed/coocaa-designer-vue'
import 'nprogress/nprogress.css'
import { getCookie, getTokenName } from '@/utils/common.js'
let loadingInstance
export default function fetch ({
  method = 'get',
  url,
  data,
  params,
  options = {},
  isJSON = true,
  emptyToken = false, // 是否需要token
  isLoading = true,
  isFile = false,
  isReturnAll = false,
  hideError = false
}) {
  // NProgress.start()
  if (!loadingInstance && isLoading) {
    loadingInstance = Loading.service()
  }
  const option = {
    method,
    url: `/violet-api/${url}`,
    data: (isJSON || data instanceof FormData)
      ? data
      : typeof data === 'string'
        ? data
        : qs.stringify(data),
    params
  }
  let token = ''
  if (!emptyToken) {
    token = getCookie(getTokenName())
  }
  // const app = this.app
  option.headers = {
    AccessToken: token || '',
    Authorization: 'Basic Y2xpZW50OmFkbWlu',
    ...options
  }
  return axios(option)
    .then(function (res) {
      if (isFile) {
        return res
      }
      const { data } = res
      if (data.success || data.code === 1000 || data.code === 200) {
        if (isReturnAll) {
          return data
        } else if (data.data) {
          return data.data
        } else {
          return data
        }
      } else {
        // eslint-disable-next-line no-throw-literal
        throw {
          code: data.code,
          message: data.msg
        }
      }
    })
    .catch(e => {
      if (!hideError) {
        const message = e.response.data.message || '系统出错了'
        Notification({
          title: e.code === 8001 ? '无权访问' : '错误',
          type: 'error',
          message
        })
      }

      if ('response' in e && e.response.data.code === '403') {
        option.headers = {
          Authorization: ''
        }
        // app.prototype.$logout().then(() => {
        //   window.location.reload()
        // })
        throw e
      } else {
        throw e
      }
    }).finally(() => {
      // NProgress.done()
      if (loadingInstance) {
        loadingInstance.close()
        loadingInstance = undefined
      }
    })
}
