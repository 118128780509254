import { RouteConfig } from 'vue-router'
import Main from '../components/layout/Main.vue'
import PageMain from '../components/layout/PageMain.vue'
import { PAGE_TYPE_DOC, PAGE_TYPE_PAGE } from '@/utils/constants'
import { isMobile } from '@/utils/common'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "parser" */ '../views/page/Index.vue'),
        meta: {
          title: '首页',
          resId: 'cms-home-key' // 资源ID 唯一
        }
      },
      {
        path: '/consult-submit',
        name: 'consultFormPage',
        component: () => import(/* webpackChunkName: "consultFormPage" */ '@/components/Consult.vue')
      },
      {
        path: '/ai-experience',
        name: 'aiExperience',
        component: () => import(/* webpackChunkName: "aiExperience" */ '@/views/aiExperience/Index.vue')
      }
      // {
      //   path: '/ai-experience/:id',
      //   name: 'aiExperienceDetail',
      //   component: () => import(/* webpackChunkName: "aiExperience" */ '@/views/aiExperience/Detail.vue')
      // }
    ]
  },
  {
    path: '/page',
    name: 'page',
    component: PageMain,
    meta: {
      title: ''
    },
    children: [
      {
        path: ':id',
        name: 'page-detail',
        component: () => import(/* webpackChunkName: "parser" */ '../views/page/Index.vue'),
        meta: {
          title: '页面',
          idKey: 'id',
          pageType: PAGE_TYPE_PAGE // 页面类型
        }
      },
      {
        path: ':id',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
        meta: {
          title: '页面',
          idKey: 'id',
          pageType: PAGE_TYPE_PAGE // 页面类型
        }
      }
    ]
  },
  {
    path: '/product',
    name: 'page',
    component: PageMain,
    meta: {
      title: ''
    },
    children: [
      {
        path: ':code/:id',
        name: 'page-detail',
        component: () => import(/* webpackChunkName: "parser" */ '../views/page/Index.vue'),
        meta: {
          title: '页面',
          idKey: 'id',
          pageType: PAGE_TYPE_PAGE // 页面类型
        }
      }
    ]
  },
  {
    path: '/sdk/:id?',
    name: 'sdk',
    // redirect: { name: 'main' },
    component: () => import(/* webpackChunkName: "document" */ '../views/sdk/Index.vue'),
    meta: {
      title: '组件中心',
      resId: 'cms-sdk-key' // 资源ID 唯一
    }
  },
  {
    path: '/document',
    name: 'document',
    component: () => import(/* webpackChunkName: "document" */ '../views/document/Index.vue'),
    meta: {
      title: '文档中心',
      resId: 'cms-document-key' // 资源ID 唯一
    },
    children: [
      {
        path: '/document',
        name: '文档列表',
        // redirect: { name: 'main' },
        component: () => import(/* webpackChunkName: "document" */ '../views/document/DocHome.vue'),
        meta: {
          title: '文档详情',
          resId: 'cms-document-key', // 资源ID 唯一
          resUniqueId: '1691745257898639360'
        }
      },
      {
        path: '/document/search',
        name: '文档搜索',
        // redirect: { name: 'main' },
        component: () => import(/* webpackChunkName: "document" */ '../views/document/SearchRes.vue'),
        meta: {
          title: '文档搜索',
          resId: 'cms-document-search-key' // 资源ID 唯一
        }
      },
      {
        path: '/document/:spaceId',
        name: 'doc-space',
        // redirect: { name: 'main' },
        component: () => import(/* webpackChunkName: "document" */ '../views/document/DocSpace.vue'),
        meta: {
          title: '空间'
        },
        children: [
          {
            path: ':docId',
            name: 'doc-detail',
            // redirect: { name: 'main' },
            component: () => import(/* webpackChunkName: "document" */ '../views/document/DocDetail.vue'),
            meta: {
              title: '文档详情',
              idKey: 'docId',
              pageType: PAGE_TYPE_DOC // 页面类型
            }
          }
        ]
      }
    ]
  },
  // 问卷调查页面
  {
    path: '/questionnaire/:id?',
    name: 'Questionnaire',
    component: () => import(/* webpackChunkName: "myDocSpace" */ '../views/questionnaire/Index.vue'),
    meta: {
      title: '问卷调查',
      mode: 'read'
    }
  },
  {
    path: '/qnFeedback',
    name: 'QnFeedBack',
    component: () => import(/* webpackChunkName: "myDocSpace" */ '../views/questionnaire/QnFeedback.vue'),
    meta: {
      title: '问卷调查完成页面',
      mode: 'read'
    }
  },
  {
    path: 'siteError',
    name: 'siteError',
    component: () => import(/* webpackChunkName: "about" */ '../views/SiteError.vue')
  },
  {
    path: '/me',
    name: 'myDocSpace',
    redirect: '/me/list',
    component: () => import(/* webpackChunkName: "myDocSpace" */ '../views/myDocSpace/Index.vue'),
    meta: {
      title: '我的空间',
      resId: 'cms-me-doc-key' // 资源ID 唯一
    },
    children: [
      {
        path: '/me/list/:id?',
        name: 'myDocList',
        component: () => import(/* webpackChunkName: "myDocSpace" */ '../views/myDocSpace/docList/Index.vue'),
        meta: {
          title: '我的空间列表'
        }
      },
      {
        path: '/me/collect',
        name: 'myDocCollect',
        component: () => import(/* webpackChunkName: "myDocSpace" */ '../views/myDocSpace/collect/Index.vue'),
        meta: {
          title: '我的空间收藏',
          resId: 'cms-me-collect-key' // 资源ID 唯一
        }
      }
    ]
  },
  {
    path: '/me/:id',
    name: 'EditorDocDetail',
    component: () => import(/* webpackChunkName: "myDocSpace" */ '../views/myDocSpace/EditorDocDetail.vue'),
    meta: {
      title: '我的文档详情',
      mode: 'edit',
      idKey: 'id',
      pageType: PAGE_TYPE_DOC // 页面类型
    }
  },
  {
    path: '/openDoc/:id',
    name: 'EditorDocDetail',
    component: () => import(/* webpackChunkName: "myDocSpace" */ '../views/myDocSpace/EditorDocDetail.vue'),
    meta: {
      title: '分享文档详情',
      mode: 'read',
      idKey: 'id',
      pageType: PAGE_TYPE_DOC // 页面类型
    }
  },
  {
    path: '/loginDoc/:id',
    name: 'EditorDocDetail',
    component: () => import(/* webpackChunkName: "myDocSpace" */ '../views/myDocSpace/EditorDocDetail.vue'),
    meta: {
      title: '分享文档详情',
      mode: 'edit',
      idKey: 'id',
      pageType: PAGE_TYPE_DOC // 页面类型
    }
  },
  {
    path: '/experience/:productId',
    name: 'experience',
    component: () => import(/* webpackChunkName: "experience" */ '@/views/experience/Index.vue'),
    meta: {
      title: '能力体验'
    },
    children: [
      {
        path: ':pageId',
        name: 'experience-page',
        component: () => import(/* webpackChunkName: "experience" */ '@/views/experience/PageView.vue'),
        meta: {
          title: '页面'
        }
      }
    ]
  },
  {
    path: '/agents',
    name: 'agents',
    redirect: '/agents/center',
    component:
      isMobile()
        ? () => import(/* webpackChunkName: "agents" */ '@/views/agentsMobile/Index.vue')
        : () => import(/* webpackChunkName: "agents" */ '@/views/agents/Index.vue'),
    meta: {
      title: '酷开智能体'
    },
    children: [
      {
        path: 'center',
        name: 'agents-center',
        component:
          isMobile()
            ? () => import(/* webpackChunkName: "agents" */ '@/views/agentsMobile/Find.vue')
            : () => import(/* webpackChunkName: "agents" */ '@/views/agents/Center.vue'),
        meta: {
          title: '酷开智能体',
          isHideConsult: isMobile()
        }
      },
      {
        path: 'history',
        name: 'agents-history',
        component: () => import(/* webpackChunkName: "agents" */ '@/views/agentsMobile/History.vue'),
        meta: {
          title: '历史对话',
          isHideConsult: isMobile()
        }
      },
      {
        path: 'collect',
        name: 'agents-collect',
        component: () => import(/* webpackChunkName: "agents" */ '@/views/agentsMobile/collect.vue'),
        meta: {
          title: '收藏',
          isHideConsult: isMobile()
        }
      },
      {
        path: 'my',
        name: 'agents-my',
        component: () => import(/* webpackChunkName: "agents" */ '@/views/agentsMobile/my.vue'),
        meta: {
          title: '我的',
          isHideConsult: isMobile()
        }
      },
      {
        path: ':agentId',
        name: 'agents-app',
        component:
          isMobile()
            ? () => import(/* webpackChunkName: "agents" */ '@/views/agentsMobile/AppView.vue')
            : () => import(/* webpackChunkName: "agents" */ '@/views/agents/App.vue'),
        meta: {
          title: '智能体'
        }
      }
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  }
]
export default routes
