/*
 * @Date: 2024-11-25 17:53:45
 * @LastEditors: 'lidianbin' 'lidianbin@coocaa.com'
 * @LastEditTime: 2024-11-29 14:44:24
 * @FilePath: \cms_main_site\src\plugin.ts
 */
import Vue from 'vue'
// import 'element-ui/lib/theme-chalk/index.css'
import '@/theme/index.css'
import './bus'
import './auth'
import './gather'
import '@/components/svgIcon/index'
import 'animate.css'

import ElementUI from 'element-ui'
import AdminToolkit from '@cseed/admin-toolkit'
import PluginEngineSaas from '@ccprivate/plugin-engine-saas'
import coocaaDesigner from '@cseed/coocaa-designer-vue'
import '@cseed/coocaa-designer-vue/dist/theme/index.css'
import fetch from '@/services/fetch'
import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'

import data from '@emoji-mart/data'
import { init } from 'emoji-mart'

init({ data })

// import './utils/remResize.js'
Vue.use(JsonViewer)

Vue.use(ElementUI)
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 9999 }

Vue.use(coocaaDesigner)
Vue.use(AdminToolkit)
Vue.use(PluginEngineSaas, { useLoader: true, apiFetch: (...args: any) => fetch(...args).then(data => ({ data })) }) // 开启SAAS插件功能
